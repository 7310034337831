import { useState, useCallback, useEffect } from "react";
import BankInput from "../BankInput";
import Input from "../Input";
import Modal from "../Modal";
import useGetInvitationData from "../../hooks/useGetInvitationData";
import { useAppSelector } from "../../app/hooks";
import { acceptAjoInvite, acceptAjoWithDD, getDirectDebitBanks } from "../../actions/dashboardActions";
import toast from "react-hot-toast";
import useShowNewFeature from "../../hooks/useShowNewFeature";

const frequencyMap = {
  Daily: 'Day',
  Weekly: 'Week',
  Monthly: 'Month'
}

const AcceptAjo = () => {
    const [showForm, setShowForm] = useState(false);
    const [linkData, setLinkData] = useState({});
    const [inviteData] = useGetInvitationData();

    const invitationData = localStorage.getItem('pendingInvitation');
    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
    const {user} = useAppSelector((state) => state.auth)
    useEffect(() => {
        if (invitationData && JSON.parse(invitationData)?.action === 'accept' && isAuthenticated) {
            setShowForm(true);
            setLinkData(JSON.parse(invitationData));
        }
    }, [inviteData, isAuthenticated, invitationData]);

    if (inviteData.email !== user?.email && !isAuthenticated) {
      return null;
    }
  
    return (
        <AcceptAjoForm showForm={showForm} inviteData={linkData}/>
    );
};

export default AcceptAjo;

export const AcceptAjoForm = ({showForm, inviteData, handleDone = () => {}, closeFrmParent = (val: boolean) => {}}) => {
    const {user} = useAppSelector((state) => state.auth);
    const showFeature = useShowNewFeature();
    const [showAcceptInvite, setShowAcceptInvite] = useState(false);
    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
    const address = useAppSelector((state) => state.auth.user?.address);
    const [ddBanks, setDDBank] = useState<Record<string, string>[]>([]);
    const [ajoData, setAjoData] = useState<Record<string, any>>({});
    const [useManual, setUseManual] = useState(false);
    const [description, setDescription] = useState('');
    let invitationData = inviteData;
    if (invitationData) {
      invitationData = invitationData;
    }
    const [selectedBank, setSelectedBank] = useState({});
    const [ajoAcceptData, setAjoAcceptData] = useState({
      selectedAcct: '',
      position: '',
      declineNote: '',
      bankName: '',
    });

    useEffect(() => {
      if (showFeature) {
        isAuthenticated && getDirectDebitBanks((resp) => {
          setDDBank(resp);
        });
      }
    }, [isAuthenticated]);
  
    const handleChange = useCallback((name: string, value: string) => {
      setAjoAcceptData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }, []);

    const handleAccept = async () => {
      if (!ajoAcceptData.bankName) {
        toast.error('Please select a collection bank.');
        return;
      }
      // @ts-ignore
      const bankIsDDBank = ddBanks.find((bank) => bank.nipCode === selectedBank.bankCode);
      if (!bankIsDDBank) {
        setUseManual(true);
        return toast('Selected bank does not allow direct debit');
      }
      acceptAjoWithDD({
        // @ts-ignore
        inviteLink: inviteData.url || `https://www.azatme.com/login${localStorage.getItem('inviteLink')}`,
        // @ts-ignore
        bankName: selectedBank.bankName,
        // @ts-ignore
        bankCode: selectedBank.bankCode,
        // @ts-ignore
        accountName: selectedBank.account_name,
        // @ts-ignore
        accountNumber: selectedBank.account_number,
        productId: inviteData.productId,
        productName: inviteData.productName,
        homeAddress: address,
        description,
        paymentFrequency: inviteData.frequency,
        remarks: description,
        ajoId: inviteData.ajoId,
        // @ts-ignore
        referenceId: selectedBank.referenceId
      }, () => {
        localStorage.removeItem('pendingInvitation');
        setShowAcceptInvite(false);
        handleDone();
      }, () => {
        setUseManual(true);
      })
    }

    useEffect(() => {
      if (invitationData) {
        setAjoAcceptData((prev) => ({
          ...prev,
          // @ts-ignore
          position: invitationData.position
        }))
      }
      
    }, [])

    useEffect(() => {
        setShowAcceptInvite(showForm);
    }, [showForm]);

    const handleAcceptManual = () => {
      acceptAjoInvite({email: user?.email}, () => {
        setShowAcceptInvite(false);
      })
    }
  
    return (
        <Modal
          open={showAcceptInvite}
          onClose={() => {
            setShowAcceptInvite(false);
            closeFrmParent(false);
          }}
          overrideStyle={{
            maxWidth: '400px',
            width: '100%',
            height: 'max-content',
            maxHeight: '70%',
            border: '1px solid red',
          }}
          title="Accept Ajo Invite"
        >
          {
            useManual ? (
              <div>
                <p className="w-full text-center py-10">Your direct debit configuration failed. </p>
                <div className='flex w-full flex-row justify-around items-center py-10'>
                  <button className='w-5/12 px-6 border bg-az-teal py-2 text-white text-base' type="button" onClick={handleAcceptManual}>Use manual payment</button>
                  <button className='w-5/12 px-6 border bg-az-teal py-2 text-white text-base' type="button" onClick={() => setUseManual(false)}>Try direct debit again</button>
                </div>
              </div>
            ) : (
              <form action="" className='w-full py-8 my-6'>
                <div className='!w-full'>
                  <BankInput
                    setSelectedBank={(value) => {
                      setSelectedBank(value);
                      handleChange('bankName', value.bankName);
                      const ddBank = ddBanks?.find((bank) => bank.nipCode === value.bankCode);
                        if (ddBank) {
                          setSelectedBank(value);
                          handleChange('bankName', value.bankName);
                        } else {
                          setSelectedBank(value);
                          handleChange('bankName', value.bankName);
                          setUseManual(true);
                          toast('Selected bank currently doesn\'t support direct debit, select or add another bank.');
                        }
                    }} 
                  />
                </div>
                <Input
                  label='Your assigned collection positon'
                  name={''}
                  // @ts-ignore
                  value={invitationData?.position}
                  readonly
                />
                <Input
                  label='Description'
                  name={'description'}
                  maxLength={150}
                  // @ts-ignore
                  value={description}
                  onChange={(name, val) => setDescription(val)}
                />
                <div className='flex w-full flex-row justify-around items-center'>
                  <button className='w-fit px-6 border bg-az-teal py-2 text-white' type="button" onClick={handleAccept}>Accept with Direct Debit</button>
                </div>
              </form>
            )
          }
        </Modal>
    );
};
